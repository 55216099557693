import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

export default function Table({ data }) {
  return (
    <div>
      <Header data={data} />
      <br />
      <Body data={data} />
      <Footer data={data} />
    </div>
  );
}
