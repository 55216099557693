import React from "react";

export default function Methoden({ methoden }) {
  const printMethode = [];
  const test = [];

  function printMethoden() {
    for (var i = 0; i < 12; i++) {
      test.push();
      methoden[i]["Feldbeschreibung"] !== "" &&
        methoden[i]["Feldbeschreibung"] &&
        printMethode.push(methoden[i]["Feldbeschreibung"]);
    }
  }

  printMethoden();

  return (
    <div>
      <table>
        <tbody>
          {printMethode.map((methode, idx) => (
            <tr key={idx} className="small">
              <td>
                *{++idx} {methode}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
}
