import React from "react";

function decimal(decimal, wert) {
  let checkNumber = Number(wert).toFixed(2).slice(-1);

  if (decimal === 0) {
    return Number(wert).toFixed(0);
  } else if (decimal === 1) {
    return Number(wert).toFixed(1);
  } else if (decimal === 2) {
    if (checkNumber === "0") {
      return Number(wert).toFixed(1);
    } else {
      return Number(wert).toFixed(2);
    }
  } else if (decimal === 3) {
    return Number(wert).toFixed(2);
  }
}

const TableRow = (props) => {
  let feld = <td>{props.feld}</td>;
  let trenner = <td className="trenner__table">:</td>;
  let wert = (
    <td className="text-right">
      {props.wert !== 0
        ? props.vortext +
          " " +
          decimal(props.dezimal, props.wert) +
          " " +
          props.text
        : null}
    </td>
  );

  let index = (
    <td className="small right trenner__index">
      {props.methode !== "" ? "*" + props.index : ""}
    </td>
  );

  return (
    <tr>
      {props.feld === "" ? null : feld}
      {props.feld === undefined ? null : trenner}

      {props.nn === 1 ? <td className="text-right">{props.sprache}</td> : wert}
      {props.feld === undefined ? null : index}
    </tr>
  );
};

export default TableRow;
