import logo from "../../img/Hopsteiner_Logo.svg";

export default function Header() {
  return (
    <div>
      <img
        src={logo}
        width="300px"
        alt="Hopsteiner Logo"
        className="header__logo"
      />
      <hr className="header__hr" />
      <br />
    </div>
  );
}
