import { companyInfo } from "../../companyInfo.jsx";

export default function Footer() {
  return (
    <div className="footer">
      <hr />
      <div className="row small">
        {companyInfo.map((company, idx) => (
          <div key={idx} className="col ">
            <p>
              {company.name}
              <br />
              {company.location}
              <br />
              Tel: {company.phone}
              <br /> Fax: {company.fax}
              <br /> E-mail:{" "}
              <a href={`mailto:${company.email}`}>{company.email}</a>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
