export const companyInfo = [
  {
    name: " S.S Steiner, Inc.",
    location: "New York, USA",
    phone: "(1) 212 838 8900",
    fax: "(1) 212 593 4338",
    email: "sales@hopsteiner.com ",
  },
  {
    name: "Simon H. Steiner, Hopfen, GmbH Inc.",
    location: "Mainburg, Germany",
    phone: "(49) 8751 8605-0",
    fax: "(49) 8751 8605-80",
    email: "info@hopsteiner.de",
  },
  {
    name: "Steiner Hops Ltd.",
    location: "Epping, England",
    phone: "(44) 1992 572331",
    fax: "(44) 1992 573780",
    email: "enquiries@hopsteiner.co.uk",
  },
  {
    name: "Hopsteiner Trading (Zhuhai) Co., Ltd.",
    location: "Zhuhai, PRC",
    phone: "(86) 756 322 3340",
    fax: "(86) 756 322 3345",
    email: "info@hopsteiner.com.cn",
  },
];
