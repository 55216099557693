import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from "./pages/Home";
import Cert from "./pages/Cert";
import Page404 from "./pages/404";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/styles.css";

function App() {
  return (
    <div className="App">
      <main>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/:id"  element={<Cert />} />
          <Route exact path="/:id/:zeile"  element={<Cert/>} />
          <Route exact path="/:id/:zeile/A:tlz"  element={<Cert/>} />
          <Route path="*" element={<Page404/>} />
        </Routes>
      </main>
    </div>
  );
}

export default () => (
  <I18nextProvider i18n={i18n}>
    <Router>
      <App />
    </Router>
  </I18nextProvider>
);
