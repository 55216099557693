import Header from "../components/page/Header";
import Footer from "../components/page/Footer";

import Table from "../components/table/Table";

export default function Analytic({ data }) {
  return (
    <div className="container">
      <Header />
      <Table data={data} />
      <Footer />
    </div>
  );
}
