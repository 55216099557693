import React from "react";
import logo from "../img/Hopsteiner-Logo.png";
import i18n from "../i18n";

const Page404 = () => {
  return (
    <div>
      <img
        className="mx-auto d-block start-logo"
        src={logo}
        alt="Simon H. Steiner, Hopfen, GmbH"
      />
      <br />
      <br />

      <p className="text-center">{i18n.t("notFound")}</p>
    </div>
  );
};

export default Page404;
