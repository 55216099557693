import useSWR from "swr";
import { useParams, useLocation } from 'react-router-dom';
import fetcher from "../lib/fetcher";

import Analytic from "../components/Analytic";
import Spinner from "../components/Spinner";
import Error from "../components/Error";

function Cert() {
  const { id, zeile, tlz } = useParams();
  const hash = useLocation().search;

  const baseUrl = `/api/certs/${id}`;
  const zeileUrl = zeile ? `/${zeile}` : "";
  const tlzUrl = tlz ? `/A${tlz}` : "";

  const url = baseUrl + zeileUrl + tlzUrl + hash;
  const { data, error } = useSWR(url, fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return <Analytic data={data.data} />;
}

export default Cert;
