import React from "react";
import spinner from "../img/spinner.gif";

export default function Spinner() {
  return (
    <div>
      <img src={spinner} alt="Loading..." className="spinner mx-auto d-block" />
      <br />
    </div>
  );
}
