import logo from "../img/Hopsteiner_Logo.svg";
import i18n from "../i18n";

export default function Error({ error }) {
  return (
    <div>
      <img
        className="mx-auto d-block start-logo"
        src={logo}
        alt="Simon H. Steiner, Hopfen, GmbH"
      />
      <br />
      <br />

      <p className="text-center">{i18n.t("notFound")}</p>
    </div>
  );
}
