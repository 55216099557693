import { date } from "../../lib/date";

export default function Header({ data }) {
  const {
    sprache,
    spvk,
    untersuchung,
    lief,
    verpack,
    deb,
    prod,
    bio,
    gebiet,
    sorte,
  } = data;

  return (
    <div>
      <div className="row">
        <div className="col">
          <h2 className="text-left">
            <strong>
              <u>{sprache.Analysenzertifikat}</u>
            </strong>
          </h2>
        </div>
        <div className="col">
          <p className="text-right">{date(new Date())}</p>
        </div>
      </div>
      <br />
      <table className="font-weight-bold">
        <tbody>
          <tr>
            <td>{sprache.Batch}</td>
            <td className="trenner">:</td>
            <td>{untersuchung["Batch-Nummer"]}</td>
          </tr>
          <tr>
            <td />
            <td />
            <td className="small font-weight-bold">
              {!untersuchung["Verwendete Chargen_Interime 1"]
                ? null
                : untersuchung["Verwendete Chargen_Interime 1"]}
            </td>
          </tr>
          <tr>
            <td>{lief["Kontrakt-Nummer"]}</td>
            <td className="trenner">:</td>
            <td>
              {untersuchung["Kontrakt-Nummer"]}
              {untersuchung["TLZ-Nummer"] !== 0
                ? "/" +
                  Number(untersuchung["TLZ-Nummer"] / 10000) +
                  "/A" +
                  Number(
                    (untersuchung["Gehört zu Zeilennummer"] - 10000000) / 10000
                  )
                : "/" + Number(untersuchung["Gehört zu Zeilennummer"] / 10000)}
            </td>
          </tr>
          <tr>
            <td>{lief["Kunden-Bestellnummer"]}</td>
            <td className="trenner">:</td>
            <td>
              {verpack.KundenReferenz === "" ? null : verpack.KundenReferenz}
            </td>
          </tr>
          <tr>
            <td>{lief.Warenbezeichnung}</td>
            <td className="trenner">:</td>
            <td>
              {deb.KVA === 1
                ? deb.Signo.replace("§", "HOPSTEINER").replace(
                    "?",
                    "KVA-" + prod.Produkt
                  )
                : bio.Bio === 1
                ? deb.Signo.replace("§", "HOPSTEINER").replace(
                    "?",
                    prod.Produkt
                  )
                : deb.Signo.replace("§", "HOPSTEINER").replace(
                    "?",
                    prod.Produkt
                  )}

              {bio.Bioland === 1 ? ` (${spvk.Bioland})` : null}
              {bio.Naturland === 1 ? ` (${spvk.Naturland})` : null}
              {bio.Demeter === 1 ? ` (${spvk.Demeter})` : null}
            </td>
          </tr>
          <tr>
            <td> {lief.Anbaugebiet}</td>
            <td className="trenner">:</td>
            <td>{gebiet["Anbaugebiet"]}</td>
          </tr>
          <tr>
            <td> {lief.Sorte}</td>
            <td className="trenner">:</td>
            <td>{sorte["Sorte"]}</td>
          </tr>

          {deb["Erntejahr nicht andrucken"] === 0 ? (
            <tr>
              <td>{lief.Ernte}</td>
              <td className="trenner">:</td>
              <td>{untersuchung.Jahr}</td>
            </tr>
          ) : null}

          {untersuchung.Produktionsdatum === "" ? null : (
            <tr>
              <td>{sprache.Produktionsdatum}</td>
              <td className="trenner">:</td>
              <td>{untersuchung.Produktionsdatum}</td>
            </tr>
          )}
          {untersuchung.Mindesthaltbarkeitsdatum === "" ? null : (
            <tr>
              <td>{sprache.Mindesthaltbarkeitsdatum}</td>
              <td className="trenner">:</td>
              <td>{untersuchung.Mindesthaltbarkeitsdatum}</td>
            </tr>
          )}
          <tr>
            <td> </td>
          </tr>

          {untersuchung.Methodennummer === "ÖLE" ? (
            <tr>
              <td>
                <br />
                {sprache.Verdünnung}
              </td>
              <td className="trenner">
                <br />:
              </td>
              <td>
                <br />

                {deb.Verdünnung === 0 ? sprache.unverdünnt : null}
                {deb.Verdünnung === 1 ? sprache.Verdünnt + " 1:100" : null}
                {deb.Verdünnung === 2 ? sprache.Verdünnt + " 1:1000" : null}
                {deb.Verdünnung === 3
                  ? sprache.Verdünnt +
                    " 1:" +
                    deb["Kundenspezifische Verdünnung"]
                  : null}

                {deb["Verdünnt mit"] === 0 ? " " + sprache["in Ethanol"] : null}
                {deb["Verdünnt mit"] === 1 ? " " + sprache["in Glykol"] : null}
                {deb["Verdünnt mit"] === 2
                  ? " " + sprache["5% Ethanol und 95%PG"]
                  : null}
                {deb["Verdünnt mit"] === 3
                  ? sprache.Verdünnt + deb["Kundenspezifisch verdünnt mit"]
                  : null}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      <br />
      <hr />
      <br />
      <strong>
        <u>
          {untersuchung.Methodennummer === "ÖLE"
            ? sprache.Aromakomponenten + "*"
            : sprache.Analysenmethode + " " + untersuchung.Methode + "*"}
        </u>
      </strong>
    </div>
  );
}
