import React from "react";
import TableRow from "./TableRow";

export default function Body({ data }) {
  const { felder, untersuchung, sprache, methoden } = data;
  const tableRows = [];

  // Wichtig für die checkIndex Funktion
  let i = 1;
  // Zählt die hochgestellte Nummerierung der Analysemethoden hoch
  function checkIndex(methode) {
    return !methode ? null : i++;
  }

  // Zähle bis zur 12. Methode hoch und gebe die entsprechende Zeile zurück
  function printZeilen() {
    for (var count = 1; count <= 12; count++) {
      felder[`Feld${count}`] &&
        tableRows.push(
          <TableRow
            key={count}
            feld={felder[`Feld${count}`]["Übersetzung"]}
            vortext={untersuchung[`VorText ${count}`]}
            wert={
              count <= 4
                ? untersuchung[`Wert${count}`]
                : untersuchung[`ZusatzWert${count - 4}`]
            }
            text={
              count <= 4
                ? untersuchung[`Text ${count}`]
                : untersuchung[`ZusatzText${count - 4}`]
            }
            nn={untersuchung[`Nicht dedizierbar ${count}`]}
            dezimal={untersuchung[`Dezimalstellen${count}`]}
            methode={untersuchung[`Methode${count < 10 ? "0" + count : count}`]}
            sprache={sprache["nicht nachweisbar"]}
            index={checkIndex(methoden[count - 1]["Feldbeschreibung"])}
          />
        );
    }
  }

  printZeilen();

  return (
    <table>
      <tbody>{tableRows.map((row) => row)}</tbody>
    </table>
  );
}
