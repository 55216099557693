import Methoden from "./Methoden";

export default function Footer({ data }) {
  const { sprache, bio, bio_lang, dve, untersuchung, methoden } = data;

  return (
    <div>
      <hr />
      <div className="row">
        <div className="col">
          <p>{sprache["Automatisch erstellt"]}</p>
          <p>
            {bio.Bio === 1
              ? bio_lang["Bio-Kontrollstelle"] +
                ": " +
                dve["Bio-Kontrollstelle"]
              : null}
          </p>
          <p>
            {untersuchung["Spritzmittel-Norm-Text"]
              ? untersuchung["Spritzmittel-Norm-Text"]
              : ""}
          </p>
        </div>
        <div className="col">
          <Methoden methoden={methoden} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="small">{sprache.Methode}</p>
        </div>
        <div className="col">
          <span className="float-right">
            {sprache["Erklärung nicht nachweisbar"]}
          </span>
        </div>
      </div>
    </div>
  );
}
