import logo from "../img/Hopsteiner_Logo.svg";

function Home() {
  return (
    <div className="container-fluid">
      <img
        className="mx-auto d-block start-logo img-fluid"
        src={logo}
        width="400"
        alt="Simon H. Steiner, Hopfen, GmbH"
      />
      <h1 className="display-2 text-center">Analytics</h1>
    </div>
  );
}

export default Home;
